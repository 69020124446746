<template>
  <v-container>
    <v-form ref="blog-category-form" @submit.prevent="formSubmit">
      <v-row>
        <v-col cols="12" class="d-flex">
          <v-btn @click.prevent="$router.go(-1)" plain :disabled="isFetching">
            <v-icon>{{ icons.mdiChevronLeft }}</v-icon>
            <div>上一頁</div>
          </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-card rounded="lg" class="pa-5">
            <v-card-subtitle>
              <div class="page-title">{{ $route.meta.title }}</div>
            </v-card-subtitle>
            <v-card-text class="my-5">
              <v-row>
                <v-col cols="12" md="6">
                  <form-control
                    inputType="string"
                    v-model="name"
                    label="分類名稱"
                    :required="true"
                    :disabled="isFetching"
                  />
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions>
              <v-row>
                <v-col cols="12">
                  <v-btn color="primary white--text" class="px-6" depressed small :disabled="isFetching" type="submit"
                    >儲存</v-btn
                  >
                </v-col>
              </v-row>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>
 
 <script>
import { mapActions, mapState } from 'vuex'
import FormControl from '@/components/form/FormControl.vue'
import { mdiChevronLeft } from '@mdi/js'

export default {
  name: 'blogCategoryDetail',
  components: {
    FormControl,
  },
  setup() {
    return {
      icons: {
        mdiChevronLeft,
      },
    }
  },
  data() {
    return {
      name: '',
    }
  },
  computed: {
    ...mapState({
      isFetching: state => state.request.isFetching,
    }),

    id: function () {
      return this.$route.params.id
    },
  },
  methods: {
    ...mapActions(['setFetching'], 'request'),
    ...mapActions(['setDialogMessage', 'setShowDialog']),

    async getCourseCategoryDetail() {
      let categoryError = false
      let message = '資料不存在'

      try {
        const payload = {
          id: parseInt(this.id),
        }

        const result = await this.$XHR.api('get_blog_category_by_id', payload)
        this.$func.log('-----Get Blog Category Detail-----')
        this.$func.log(result)

        this.name = result.category_name

        this.$store.dispatch('toggleLoadingPage', false)
      } catch (error) {
        this.$func.log('-----Get Blog Category Detail Fail-----')
        this.$func.log(error)

        if (error.data === 'admin verification fail') {
          this.forceLogout()
        } else if (error.data === 'no permission') {
          categoryError = true
          message = '沒有權限'
        } else {
          categoryError = true
        }
      }

      if (categoryError) {
        this.$store.dispatch('toggleAlertMessage', {
          show: true,
          message,
          type: 'error',
          refresh: false,
          redirect: 'blogCategory',
        })
      }
    },

    async formSubmit() {
      if (!this.isFetching) {
        this.setFetching(true)

        const isValid = this.$refs['blog-category-form'].validate()

        if (!isValid) {
          this.$store.dispatch('toggleAlertMessage', {
            show: true,
            message: '請先填滿所有必填項目',
            type: 'error',
            refresh: false,
            redirect: '',
          })
          this.setFetching(false)
          return
        }

        try {
          const payload = {
            data_type: 'blog_category_data',
            verify_token: this.getAdminToken(),
            status: 'active',
            data_status: 'active',
            category_name: this.name,
          }

          let key = ''
          if (this.$validate.DataValid(this.id)) {
            payload.id = parseInt(this.id)
            key = 'cms_update_blog_category'
          } else {
            key = 'cms_add_blog_category'
          }

          const result = await this.$XHR.api(key, payload)
          this.$func.log('-----update blog category detail-----')
          this.$func.log(result)

          this.setDialogMessage({
            message: '儲存成功',
            isError: false,
            returnLink: { name: 'blogCategory' },
          })

          this.setShowDialog(true)
          this.setFetching(false)
        } catch (error) {
          this.$func.log('-----update blog category detail fail-----')
          this.$func.log(error)

          let message = '儲存失敗'

          if (error.data === 'admin verification fail') {
            this.forceLogout()
          } else if (error.data === 'no permission') {
            message = '沒有權限'
          } else if (error.data === 'category not found') {
            message = '日誌分類不存在'
          }

          this.setFetching(false)
          this.setDialogMessage({
            message: message,
            isError: true,
            returnLink: null,
          })
          this.setShowDialog(true)
        }
      }
    },
  },
  async created() {
    this.$store.dispatch('toggleLoadingPage', true)
    const check = await this.checkUserData()
    if (check) {
      if (!this.$validate.DataValid(this.id)) {
        this.$store.dispatch('toggleLoadingPage', false)
      } else {
        this.getCourseCategoryDetail()
      }
    }
  },
}
</script>
 